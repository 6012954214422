import tw, { css } from "twin.macro";
import tealBacking from "../../assets/resource-thumbnail-backing-teal.svg";
import iconChatteal from "../../assets/icon-chat-teal.svg";
import iconPhoneteal from "../../assets/icon-phone-teal.svg";
import iconTextteal from "../../assets/icon-text-teal.svg";
import iconAudioOnteal from "../../assets/icon-audio-on-teal.svg";
import iconAudioOffteal from "../../assets/icon-audio-off-teal.svg";
import iconFeedbackteal from "../../assets/icon-feedback-teal.svg";

import iconNothelpful from "../../assets/feedback/icon-nothelpful-teal.svg";
import iconNeitherhelpfulnorunhelpful from "../../assets/feedback/icon-neither-teal.svg";
import iconHelpful from "../../assets/feedback/icon-helpful-teal.svg";

import iconNothelpfulHover from "../../assets/feedback/icon-nothelpful-hover.svg";
import iconNeitherhelpfulnorunhelpfulHover from "../../assets/feedback/icon-neither-hover.svg";
import iconHelpfulHover from "../../assets/feedback/icon-helpful-hover.svg";

export const ThemeTeal = {
	textColor: tw`text-teal`,
	bgColor: tw`bg-teal`,
	textHoverColor: tw`hover:text-teal`,
	buttonLight: tw`bg-gradient-to-r from-teal-gradient-light1 to-teal-gradient-light2`,
	headerGradient: tw`bg-gradient-to-r  from-teal-header-gradient1 to-teal-header-gradient2`,
	bgColorA: [220.0 / 255.0, 250.0 / 255.0, 250.0 / 255.0, 1.0],
	bgColorB: [144.0 / 255.0, 238.0 / 255.0, 224.0 / 255.0, 1.0],	
	inputBorder: tw`border-teal-light`,
	buttonDarkBorder: css`
		&:before {
			${tw`bg-gradient-to-r from-teal-gradient-dark1 to-teal-gradient-dark2`};
		}
	`,
	categoryColor: tw`bg-gradient-to-b from-teal-gradient-dark1 to-teal-gradient-dark2`,
	buttonLighter: tw`bg-gradient-to-b from-teal-gradient-lighter1 to-teal-gradient-lighter2`,
	thumbnailBackingRight: tealBacking,
	thumbnailBackingLeft: tw`bg-gradient-to-br from-teal-gradient-thumbnail-left1 to-teal-gradient-thumbnail-left2`,
	iconPhone: iconPhoneteal,
	iconChat: iconChatteal,
	iconText: iconTextteal,
	iconAudioOn: iconAudioOnteal,
	iconAudioOff: iconAudioOffteal,
	iconFeedback: iconFeedbackteal,
	iconNothelpful: iconNothelpful,
	iconNeitherhelpfulnorunhelpful: iconNeitherhelpfulnorunhelpful,
	iconHelpful: iconHelpful,
	iconNothelpfulHover: iconNothelpfulHover,
	iconNeitherhelpfulnorunhelpfulHover: iconNeitherhelpfulnorunhelpfulHover,
	iconHelpfulHover: iconHelpfulHover,
	iconHoverGradient: tw`hover:bg-gradient-to-b hover:from-teal-gradient-dark1 hover:to-teal-gradient-dark2`,
	buttonBorder: tw`border-teal`,
};
